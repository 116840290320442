import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { media } from '@/utils/mixin'
import ChevronIcon from '@/assets/svg/chevron-down-coloured.svg'
import { useCardTemplate } from '@/modules/Cabinet/components/Cards/components/CardTemplate/useCardTemplate'

interface Props {
  isIcon?: boolean
  children?: React.ReactNode
}

function CardTop({ isIcon, children }: Props) {
  const { isOpen, updateOpennessStatus } = useCardTemplate()

  return (
    <Component
      isOpen={isOpen}
      isIcon={isIcon}
      onClick={() => updateOpennessStatus(!isOpen)}
    >
      {children}
      {isIcon && (
        <Icon>
          <ChevronIcon />
        </Icon>
      )}
    </Component>
  )
}

const Component = styled.div<{ isOpen?: boolean; isIcon?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0 30px 0;
  width: 100%;

  ${media.mobile(css`
    display: block;
  `)};

  ${({ isIcon }) =>
    isIcon &&
    css`
      padding: 0 31px 30px 0;

      ${media.mobile(css`
        padding-right: 15px;
      `)}
    `};

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Icon} {
        transform: translateY(-50%) rotate(180deg);
      }
    `};
`

const Icon = styled.div`
  position: absolute;
  content: '';
  top: calc((100% - 30px) / 2);
  right: 0;
  transform: translateY(-50%);
  transition: 0.15s;
  width: 11px;
  height: 11px;
  color: ${props => props.theme.black100};

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 11px;
    height: 7px;
  }
`

export default CardTop
